import Cookies from 'js-cookie';

import { getPalette } from '../services/palleteGenerator';

/**
 * Get cookie by name
 * we can use it to get django csrf token too
 * @param csrftoken
 */
export const getCookie = (csrftoken) => {
  return Cookies.get(csrftoken);
};

/*
 * Get current Django selected languagd
 * @param {*} defaultLanguage
 * @returns
 */
export const getCurrentLanguageCode = (defaultLanguage = 'fr') => {
  // eslint-disable-next-line no-undef
  const languageCode = document.querySelector('#language-code');

  return languageCode?.length > 0 ? languageCode.value : defaultLanguage;
};

/**
 * Get current site activity title
 * @returns {T|string}
 */
export const getSiteActivityTitle = () => {
  // eslint-disable-next-line no-undef
  const siteActivityTitle = document.querySelector('#activity-title');
  return siteActivityTitle?.value || '';
};

/**
 * Get current site activity description
 * @returns {T|string}
 */
export const getSiteActivityDescription = () => {
  // eslint-disable-next-line no-undef
  const siteActivityDescription = document.querySelector(
    '#activity-description'
  );
  return siteActivityDescription?.value || '';
};

/**
 * Get current site colors
 * @returns {{primaryColor: string, secondaryColor: string, backgroundColor: string}}
 */
const getCurrentSiteColors = () => {
  // eslint-disable-next-line no-undef
  const primaryColor = document.querySelector('#primary-color');
  // eslint-disable-next-line no-undef
  const secondaryColor = document.querySelector('#secondary-color');
  // eslint-disable-next-line no-undef
  const backgroundColor = document.querySelector('#background-color');
  return {
    primaryColor: primaryColor.value,
    secondaryColor: secondaryColor.value,
    backgroundColor: backgroundColor.value,
  };
};

/**
 * Generate CSS variables for primary and secondary color
 */
export const generateCSSVariables = () => {
  const { primaryColor, secondaryColor, backgroundColor } =
    getCurrentSiteColors();
  const primaryPalette = getPalette(primaryColor);
  const secondaryPalette = getPalette(secondaryColor);
  const backgroundPalette = getPalette(backgroundColor);

  //eslint-disable-next-line no-undef
  const root = document.documentElement;

  const steps = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950];

  primaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-primary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-primary-default', primaryColor);
  secondaryPalette.forEach((color, index) => {
    root.style.setProperty(`--color-secondary-${steps[index]}`, color);
  });
  root.style.setProperty('--color-secondary-default', secondaryColor);
  backgroundPalette.forEach((color, index) => {
    root.style.setProperty(`--color-background-${steps[index]}`, color);
  });
  root.style.setProperty('--color-background-default', backgroundColor);
};

/**
 * Get current tenant to use as BASE_API_URL
 * @returns {string}
 */
export const getCurrentTenant = () => {
  // eslint-disable-next-line no-undef
  const hostname = document.querySelector('#hostname');
  return hostname?.value || process.env.BASE_URL;
};

export const setEqualHeight = (selector) => {
  const elements = document.querySelectorAll(selector);
  let maxHeight = 0;

  elements.forEach((element) => {
    element.style.minHeight = 'auto';
  });

  elements.forEach((element) => {
    const elementHeight = element.offsetHeight;
    if (elementHeight > maxHeight) {
      maxHeight = elementHeight;
    }
  });

  elements.forEach((element) => {
    element.style.minHeight = maxHeight + 'px';
  });
};
